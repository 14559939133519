<!--
 * @Description: 资讯详情页面 InformationDetails
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-04 11:31:43
 * @LastEditors: yanxiao
-->
<template>
  <div class='main'>
    <van-nav-bar title="资讯详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <div class="content">
      <h3>{{this.$route.query.contentName}}</h3>
      <h5 class="h5">
        <span>创建时间：{{createDate}}</span>
      </h5>
      <p style="text-indent:2em;">{{this.$route.query.content}}</p>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      createDate: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    const dateTime = this.$route.query.createTime.replace(/-/g, '/')
    this.createDate = this.$fmtDate(new Date(dateTime), 'yyyy年MM月dd日')
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.main {
  height: 100%;
  width: 100%;
  .content {
    height: calc(100% - 80px);
    padding: 10px;
    margin-top: 10px;
    box-sizing: border-box;
    overflow: auto;
    background: #ffffff;
    h3 {
      text-align: center;
      font-size: 20px;
    }
    .h5 {
      text-align: right;
      color: rgb(112, 112, 112);
    }
  }
}
</style>
